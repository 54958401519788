<template>
    <div>
        <!-- Top Bar And Such -->
        <div class="head sticky-header">
            <div>
                <!-- Image and text -->
                <b-navbar toggleable="lg" variant="vai-main-grey">
                <b-navbar-brand to="/home">
                    <img id="vai-navbrand" src="@/assets/images/logo/vai_logo_invert_valve_white.png" fluid alt="ValveAI Horizontal Logo">
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse" class="vai-nav-toggle"></b-navbar-toggle>
                
                <b-collapse id="nav-collapse" is-nav >
                    <b-navbar-nav>
                        <b-nav-item-dropdown text="Quick Links">
                            <div v-for="(child, index) in qnav" :key="'qnav-' + index">
                                <b-dropdown-item v-if="$store.getters.checkUIPC({path: child.uipcPath })" :disabled="!child.hasRoute" :to="{ path: child.route }">{{child.name}}</b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Create">
                            <div v-for="(child, index) in cnav" :key="'cnav-' + index">
                                <b-dropdown-item v-if="$store.getters.checkUIPC({path: child.uipcPath })" :disabled="!child.hasRoute" :to="{ path: child.route }">{{child.name}}</b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Options">
                            <div v-for="(child, index) in onav" :key="'onav-' + index">
                                <b-dropdown-item v-if="$store.getters.checkUIPC({path: child.uipcPath })" :disabled="!child.hasRoute" :to="{ path: child.route }">{{child.name}}</b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                    <b-input-group v-if="$store.getters.checkUIPC({path: ['search', 'access'] })">
                        <b-form-input class="h-auto" placeholder="Search" v-model="searchTerm" @keyup.enter.native="search()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="vai-secondary-darker" @click="search()"><b-icon-search></b-icon-search></b-button>
                        </b-input-group-append>
                    </b-input-group>  
                    <b-nav-item-dropdown right>
                        <!-- Using 'button-content' slot -->
                        <template slot="button-content"><em>{{$store.getters.displayName}}</em></template>
                        <b-dropdown-group header="Administration" v-if="$store.getters.checkUIPC({path: ['formBuilder', 'access'] }) || $store.getters.checkUIPC({path: ['formAssignment', 'access'] }) || $store.getters.checkUIPC({path: ['bulk', 'access'] }) || $store.getters.checkUIPC({path: ['accountManagement', 'access'] })">
                            <b-dropdown-item to="/home/account/manage" v-if="$store.getters.checkUIPC({path: ['accountManagement', 'access'] })">Account Management</b-dropdown-item>
                            <b-dropdown-divider v-if="$store.getters.checkUIPC({path: ['accountManagement', 'access'] })"></b-dropdown-divider>
                            <b-dropdown-item to="/home/upload/start" v-if="$store.getters.checkUIPC({path: ['bulk', 'actions', 'perform'] })">Start New Data Upload</b-dropdown-item>
                            <b-dropdown-item to="/home/upload/session/list" v-if="$store.getters.checkUIPC({path: ['bulk', 'actions', 'list'] })">Data Upload Session List</b-dropdown-item>
                            <b-dropdown-divider v-if="$store.getters.checkUIPC({path: ['bulk', 'access'] })"></b-dropdown-divider>
                            <b-dropdown-item to="/home/formbuilder" v-if="$store.getters.checkUIPC({path: ['formBuilder', 'access'] })">Form Builder</b-dropdown-item>
                            <b-dropdown-item to="/home/roz/formassignment" v-if="$store.getters.checkUIPC({path: ['formAssignment', 'access'] })">Form Assignment</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                        </b-dropdown-group>
                        
                        <b-dropdown-item @click="openProfile">Profile</b-dropdown-item>
                        <b-dropdown-item @click="logOut()" >Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
                </b-navbar>
            </div>
            <b-modal
                id="user-self-profile"
                v-model="profileModalOpen"
                title="Profile"
                ok-variant="success"
                cancel-variant="warning"
                ok-title="Save"
                cancel-title="Cancel"
                @cancel="discardProfileChanges"
                @ok="saveProfileChanges"
            >
                <div v-if="profileModalOpen && !waiting">
                    <b-form-group
                        id="profile-first-name-label"
                        description=""
                        label="First Name"
                        label-for="profile-first-name"
                    >
                        <b-form-input 
                            id="profile-first-name" 
                            v-model="profileInfo.first_name"
                            trim
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="profile-last-name-label"
                        description=""
                        label="Last Name"
                        label-for="profile-last-name"
                    >
                        <b-form-input 
                            id="profile-last-name" 
                            v-model="profileInfo.last_name"
                            trim
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="profile-display-name-label"
                        description=""
                        label="Display Name"
                        label-for="profile-display-name"
                    >
                        <b-form-input 
                            id="profile-display-name" 
                            v-model="profileInfo.display_name"
                            trim
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="waiting">
                    <wait-dots title="Saving Profile Update"></wait-dots>
                </div>
            </b-modal>
        </div>
        <!-- Begin Stage Section TODO: Fix The Margin Weirdness With Bcontainer fluid-->
        <b-container fluid class="max-height" id="primary-stage-container">
            <router-view/>
        </b-container>
        
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const butils = require('../libs/basicUtils.js')
const _ = butils.underscore;


import waitDots from '@/components/subcomponents/WaitingDots.vue'

export default {
    name: 'dash',
    components: {
        'wait-dots': waitDots,
    },
    data(){
        
        // Navigation Options
        var quickLinksNavList = [
            {
                name: 'Closeouts',
                hasRoute: true,
                route: '/home/closeout',
                uipcPath: ['closeouts','access']
            },
            {
                name: 'List Builder',
                hasRoute: true,
                route: '/home/listbuilder',
                uipcPath: ['listBuilder','access']
            },
            {
                name: 'Devices Missing GPS',
                hasRoute: true,
                route: '/home/devicestogeocode',
                uipcPath: ['bulkGeocode','access']
            },
            {
                name: 'Batch Manager',
                hasRoute: true,
                route: '/home/batchmanager',
                uipcPath: ['listBuilder','access']
            }
        ];
        var navOptsList = [
            {
                name: 'Utilities',
                hasRoute: true,
                route: '/home/utilities',
                uipcPath: ['utilities','access']
            },
            {
                name: 'Choice Lists',
                hasRoute: true,
                route: '/home/choice_lists',
                uipcPath: ['choiceLists','access']
            },
            {
                name: 'Device Models',
                hasRoute: true,
                route: '/home/device_models',
                uipcPath: ['deviceModels','access']
            },
            {
                name: 'Technicians',
                hasRoute: true,
                route: '/home/technicians',
                uipcPath: ['technicians','access']
            }
        ];
        var createOptsList = [
            {
                name: 'Customer',
                hasRoute: true,
                route: '/home/create/customers',
                uipcPath: ['dashboard', 'actions', 'createCustomer']
            },
            {
                name: 'Site',
                hasRoute: true,
                route: '/home/create/sites',
                uipcPath: ['dashboard', 'actions', 'createSite']
            },
            {
                name: 'Connection',
                hasRoute: true,
                route: '/home/create/connections',
                uipcPath: ['dashboard', 'actions', 'createConnection']
            },
            {
                name: 'Device',
                hasRoute: true,
                route: '/home/create/devices',
                uipcPath: ['dashboard', 'actions', 'createDevice']
            },
            {
                name: 'Letter Builder',
                hasRoute: true,
                route: 'home/letterbuilder',
                uipcPath: ['dashboard', 'actions', 'createLetter']
            }
        ]

        var currentTime = new Date().getHours();
        //console.log(currentTime);
        var dynGreeting = "";
        if(currentTime >= 0 && currentTime < 13){
            //Morning
            dynGreeting = "Good Morning";
        }else if(currentTime >= 13 && currentTime < 20){
            //Afternoon
            dynGreeting = "Good Afternoon";
        }else if(currentTime >= 20 && currentTime <= 23){
            //Night
            dynGreeting = "Good Evening";
        }
        var name = this.$store.getters.displayName;

        return {
            profileModalOpen: false,
            profileInfo: {},
            searchActive: false,
            searchTerm: '',
            waiting: false,
            navActiveIndex: 0,
            onav: navOptsList,
            qnav: quickLinksNavList,
            cnav: createOptsList,
            userName: name,
            greet: dynGreeting,
            showCollapse: false,
            sideNav: {
              open: true
            }
        }
    },
    methods:{
        discardProfileChanges(){
            this.profileModalOpen = false;
            this.profileInfo = _.clone(this.$store.getters.allUserInfo);
        },
        saveProfileChanges(){
            this.waiting = true;
            var pack = {
                first_name: this.profileInfo.first_name,
                last_name: this.profileInfo.last_name,
                display_name: this.profileInfo.display_name
            }
            instance.put(`${process.env.VUE_APP_API_BASE_URL}/account/users/self`, pack)
            .then((response)=>{
                // Update the changes into the store
                this.$store.commit('setUserNameInfo', pack);
                this.waiting = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Updating Profile', 'Try again, if the problem persists, contact support', 'danger');
                }
                this.waiting = false;
            })
        },
        openProfile(){
            this.profileModalOpen = true;
        },
        search(event){
            // Validate Search
            if(this.searchTerm.length > 0){
                // uri encode search value
                var uriSearchTerm = encodeURIComponent(this.searchTerm);
                this.$router.push({ path: '/home/search/' +  uriSearchTerm });
            }
        },
        // showItemDetail(item){
        //     this.searchActive = false;
        //     this.$router.push({path: '/home/devices/' + item.id })
        // },
        updateNavActive(index, name){
            this.navActiveIndex = index;
        },
        sideNavCtl(option){
            //console.log(option)
            if(option == 'close'){
                this.sideNav.open = false;
            }else{
                this.sideNav.open = true;
            }
        },
        sidenavHoverStart(evt){
            evt.target.classList.add('sidenav-item-hover')
        },
        sidenavHoverEnd(evt){
            evt.target.classList.remove('sidenav-item-hover')
        },
        logOut(){
            instance.get(process.env.VUE_APP_API_BASE_URL + "/auth/logout")
            .then((res)=>{
                console.log("Logging Out")
                this.$store.commit('logout');
                this.$router.push({ path: '/'});
            })
            .catch((err)=>{
                console.log("API Logout Request Failed")
                console.log(err);
                this.$store.commit('logout');
                this.$router.push({ path: '/'});
            })
            
        },
        goHome(openInNewTab){
            if(openInNewTab)
            this.$router.push({ path: '/home'});
        }
    },
    mounted(){
        this.profileInfo = _.clone(this.$store.getters.allUserInfo);
    }
}
</script>

<style>
.fs-0-75, .fs-0-7-5{
    font-size: 0.75rem;
    line-height: 0.75rem;
}
.fs-0-85, .fs-0-8-5{
    font-size: 0.85rem;
    line-height: 0.85rem;
}
.fs-1, .fs-1{
    font-size: 1rem;
    line-height: 1rem;
}
.fs-1-25, .fs-1-2-5{
    font-size: 1.25rem;
    line-height: 1.25rem;
}
.fs-1-5{
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.fs-1-75, .fs-1-7-5{
    font-size: 1.75rem;
    line-height: 1.75rem;
}
.fs-2, .fs-2-0{
    font-size: 2rem;
    line-height: 2rem;
}
.fs-2-25, .fs-2-2-5{
    font-size: 2.25rem;
    line-height: 2.25rem;
}
.fs-2-5{
    font-size: 2.5rem;
    line-height: 2.5rem;
}
.fs-3, .fs-3-0{
    font-size: 3rem;
    line-height: 3rem;
}
.fs-3-25, .fs-3-2-5{
    font-size: 3.25rem;
    line-height: 3.25rem;
}
.vai-flat-btn{
    padding-left: 2px;
    padding-right:2px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.vai-nav-toggle{
    background-color: #8AB0AB;
}
.navbar-light .navbar-nav .nav-link{
    color: rgba(255,255,255,0.85) !important;
}
#primary-stage-container{
    padding-left: 0px;
    padding-right: 0px;
}
.stage-inner-container-padded{
    padding-left: 15px;
    padding-right: 15px;
}
.add-link{
    color: #ffc107;
    font-size: 3rem;
    text-decoration: none;
    cursor: pointer;
}
.add-link-hover{
    color: #fd7e14;
}
.micon-1rem-icon{
    font-size: 1rem;
}
input.pill-search{
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 50rem !important;
}
button.pill-search{
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 0rem !important;
    padding-right: 1.5rem !important;
}
</style>

<style scoped>

#vai-navbrand{
    max-height: 26px;
}
.open-close-icon{
    text-decoration: none;
    margin-right: 3rem;
}
.sidenav-main{
  /* background-color: #ced4da; */
  height:100%;
}
/* .sidenav-item-hover{
  background-color: #e9ecef;
} */
.head{
    /* margin-bottom: 0.5rem; */
}
#stage{
  padding: 0px;
  margin: 0px;
}
.a-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.a-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.sidenav-slider-enter-active {
  transition: all .3s cubic-bezier(0,.45,.54,.99);
}
.sidenav-slider-leave-active {
  transition: all .3s cubic-bezier(0,.45,.54,.99);
}
.sidenav-slider-enter, .sidenav-slider-leave-to{
  transform: translateX(-50px);
  opacity: 0;
}
.sticky-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1010;
}

</style>
